var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"13caa3e461a0d6e47af49538a6dc6437b57104fb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
if (process.env.NODE_ENV === 'production') {
 const fileTestPattern =  process.env.NOTE_ENV === 'development' ? new RegExp('webpack-internal')   : new RegExp('_next/static/chunks/')

Sentry.init({
  dsn: SENTRY_DSN || 'https://834a468ebf26440b911d4587eda907d6@o174846.ingest.sentry.io/6552998',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  environment: 'production',
  beforeSend: (scope) => {
   let frames =   scope.exception.values[0].stacktrace.frames;
   let fileTest =  frames.every((frame) => fileTestPattern.test(frame)) 

    if (fileTest){
      console.log('captured')
      return scope; 
    }
    else {
      console.log('not captured'); 
      return null; 
    }

  }
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
}

